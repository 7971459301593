import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const getCartApi = createAsyncThunk(
  "get/getCart",
  async (session_id) => {
    try {
      return Axios.get(API_URL + "cart/list/" + session_id).then((response) => {
        return response.data.data;
      });
    } catch (error) {
      return error;
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [],
    subTotal : 0,
    totalPrice : 0,
    shippingPrice : 0,
    taxPrice : 0,
  },
  extraReducers: (builder) => {
    builder.addCase(getCartApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCartApi.fulfilled, (state, action) => {
      state.loading = false;
      state.cart = action.payload.result;
      state.subTotal = action.payload.sub_total;
      state.taxPrice = action.payload.tax_price;
      state.shippingPrice = action.payload.shipping_price;
      state.totalPrice = action.payload.total_price;

    });
    builder.addCase(getCartApi.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default cartSlice.reducer;
