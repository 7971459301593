import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
export const ProductComponent = (product,key) => {
  return (
    <div className="col-lg-4" key={key}>
      <Link to={`/arts/${product.product.product_slug}`}>
        <div className="artwork-box">
          <div className="img">
            <img src={product.product.feature_image} alt={product.product.product_name} />
          </div>
          <div className="content">
            <p className="text-center">{product.product.product_name}</p>
            <div className="Price text-center">{product.product.product_price}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};
