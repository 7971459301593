import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const productsFilterApi = createAsyncThunk(
  "get/getProductsFilter",
  async () => {
    try {
      return Axios.get(API_URL + "product_filter").then((response) => {
        return response.data.data.result;
      });
    } catch (error) {
      return error
    }
  }
);

const productsFilterSlice = createSlice({
  name: "productsFilter",
  initialState: {
    sizes : [],
    artists : [],
    orientations : [],
    price : [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(productsFilterApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(productsFilterApi.fulfilled, (state, action) => {
      state.loading = false;
      state.sizes = action.payload.sizes;
      state.artists = action.payload.artist;
      state.orientations = action.payload.orientation;
      state.price = action.payload.price;
    });
    builder.addCase(productsFilterApi.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default productsFilterSlice.reducer;

