import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";
//import { json } from "react-router-dom";

export const updateOrderApi = createAsyncThunk("order/updateOrder", async (postData) => {
  try {
    return Axios.post(API_URL + "confirm", postData).then((response) => {
      return response.data.data.result;
    });
  } catch (error) {
    return error
  }
});

const udpateOrderSlice = createSlice({
  name: "updateOrder",
  initialState: {
    updateOrder: [],
    loading: false,
    error: "",
  },
  extraReducers: (builder) => {
    builder.addCase(updateOrderApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOrderApi.fulfilled, (state, action) => {
      state.loading = false;
      state.updateOrder = action.payload;
    });
    builder.addCase(updateOrderApi.rejected, (state, action) => {
      //console.log(action.payload.error);
      state.loading = false;
      state.error = "invalid username/password";
    });
  },
});
export default udpateOrderSlice.reducer;
