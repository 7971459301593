import { Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link ,useNavigate } from "react-router-dom";

export const Header = ({ settings }) => {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.login);
  const { totalCart } = useSelector((state) => state.cartCount);
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    e.preventDefault();
    navigate("/arts?search=" + search );
};
  return (
    <>
      <header className="single-header">
        <div className="header-top">
          <div className="container">
            <div className="text-center">Welcome to Matti Sirvio Galleria</div>
          </div>
        </div>
        <div className=" navbar navbar-expand-lg d-block">
          <div className="header-middle">
            <div className="container">
              <div className="row align-items-center">
                <div className="navbar-toggler col-4">
                  <button
                    className=""
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                  >
                    <i className="fa fa-bars pull-right" />
                  </button>
                </div>
                <div className="header-middle col-lg-2 col-4 d-lg-block d-flex">
                  <div className="logo">
                    <Link to="/">
                      {settings.image_name && (
                        <img src={settings.image_name} alt="Logo" />
                      )}
                    </Link>
                  </div>
                </div>
                <div className="col-lg-2 col-4 d-lg-none d-block">
                  <ul className="navbar-nav justify-content-end header-end">
                    <li
                      className="nav-item cart d-block"
                      data-bs-toggle="modal"
                      data-bs-target="#search"
                    >
                      <a className="nav-link p-md-3 p-2" href="#">
                        <img
                          src={
                            window.location.origin + "/assets/images/search.png"
                          }
                          alt="search"
                        />
                      </a>
                    </li>
                    <li className="nav-item account d-lg-block ">
                      <Link
                        className="nav-link p-md-3 p-2"
                        to={auth == null ? "/login" : "/dashboard"}
                      >
                        <img
                          src={
                            window.location.origin + "/assets/images/user.png"
                          }
                          alt="user"
                        />
                      </Link>
                    </li>
                    <li
                      className="nav-item cart"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#cart"
                    >
                      <a className="nav-link p-md-3 p-2" href="#">
                        <img
                          src={
                            window.location.origin + "/assets/images/cart.png"
                          }
                          alt="cart"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="header-bottom col-8 collapse navbar-collapse justify-content-center justify-content-center justify-content-center"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav justify-content-center">
                    <li className="nav-item">
                      <Link className="nav-link" to="/pages/about-us">
                        About Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/arts">
                        Artworks
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/artist">
                        Artist Profile
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/exhibitions">
                        Exhibitions
                      </Link>
                    </li>

                    <Link className="nav-link" to="/press-release">
                      Press Release
                    </Link>
                  </ul>
                </div>
                <div className="col-lg-2 col-4 d-lg-block d-none">
                  <ul className="navbar-nav justify-content-end header-end">
                    <li className="nav-item cart d-block">
                      <a
                        className="nav-link p-md-3 p-2"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#search"
                      >
                        <img
                          src={
                            window.location.origin + "/assets/images/search.png"
                          }
                          alt="search"
                        />
                      </a>
                    </li>
                    <li className="nav-item account d-lg-block d-none">
                      <Link
                        className="nav-link p-md-3 p-2"
                        to={auth == null ? "/login" : "/dashboard"}
                      >
                        <img
                          src={
                            window.location.origin + "/assets/images/user.png"
                          }
                          alt="user"
                        />
                      </Link>
                    </li>
                    <li className="nav-item cart">
                      <Link className="nav-link p-md-3 p-2" to={"/cart"}>
                        <img
                          src={
                            window.location.origin + "/assets/images/cart.png"
                          }
                          alt="cart"
                        />
                        {totalCart > 0 && <div class="overlay">{totalCart}</div>}
                        {/* <span className="cartcount">0</span> */}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="modal search-modal" id="search">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="container">
              <div className="modal-header">
                <form onSubmit={handleSearch}   className="modal-title col-lg-7 m-auto">
                  <div className="input-group">
                    <input
                      className=" form-control"
                      placeholder="Search here.."
                      onChange={(e) => setSearch(e.target.value)}
                      defaultValue={search}
                    />
                    <button type="submit" className="input-group-text">
                      Search
                    </button>
                  </div>
                </form>
                <button
                  type="button"
                  className="btn-close m-0"
                  data-bs-dismiss="modal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
