import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";
//import { json } from "react-router-dom";

export const registerApi = createAsyncThunk(
  "post/postRegister",
  async (postData) => {
    try {
      return Axios.post(API_URL + "user/register", postData).then(
        (response) => {
          return response.data;
        }
      );
    } catch (error) {
      return error;
    }
  }
);

const registerSlice = createSlice({
  name: "register",
  initialState: {
    user: [],
    loading: false,
    error: "",
    success: "",
    auth: localStorage.getItem("user"),
  },
  extraReducers: (builder) => {
    builder.addCase(registerApi.pending, (state) => {
      state.loading = true;
      state.error ="";
      state.success ="";
      localStorage.removeItem("user");
    });
    builder.addCase(registerApi.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.data;
      if (action.payload.error == 1) {
        state.error = action.payload.message;
      }
      if (action.payload.error == 0) {
        state.success = "Register Successfully.";
      }

      // localStorage.setItem("user", JSON.stringify(action.payload));
    });
    builder.addCase(registerApi.rejected, (state, action) => {
      //console.log(action.payload.error);
      state.loading = false;
      state.error = "invalid username/password";
      state.user = [];
      //localStorage.removeItem("user");
    });
  },
});
export default registerSlice.reducer;
