import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const ArtistComponent = (artist ,key) => {
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
      <div className="artist-box">
        <div className="artist">
          <Link to={"/artist/" + artist.artist.artist_slug}>
            <div className="img">
              <img
                src={artist.artist.profile_image}
                alt={artist.artist.first_name + " " + artist.artist.last_name}
              />
            </div>
          </Link>
          <div className="content">
            <Link to={"/artist/" + artist.artist.artist_slug}>
              <div className="h6">
                {artist.artist.first_name} {artist.artist.last_name}
              </div>
            </Link>
            <div className="art-count text-muted">
              {artist.artist.total_product} Art
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
