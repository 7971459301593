import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
export const ExhibitionsComponent = (exhibition, key) => {
  const exbh = exhibition.exhibition;
  return (
    <div className="col-lg-4 col-md-6" key={key}>
      <div className="exhibition-list">
        <div className="exhibition">
          <div className="image">
            <Link to={"/exhibitions/" + exbh.exhibition_slug}>
              <img src={exbh.exhibition_image} alt="{exbh.exhibition_title}" />
            </Link>
          </div>
          <div className="mt-3">
            <div className="exhibition-date">
              {moment(exbh.exhibition_start_date).format("DD MMM YYYY")} -
              {moment(exbh.exhibition_end_date).format("DD MMM YYYY")}
            </div>
            <Link to={"/exhibitions/" + exbh.exhibition_slug}>
              <div className="exhibition-title">{exbh.exhibition_title}</div>
            </Link>
            <div className="description">
              <div
                dangerouslySetInnerHTML={{
                  __html: exbh.exhibition_short_description,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
