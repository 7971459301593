import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const updateProfileApi = createAsyncThunk(
  "post/updateProfile",
  async (postData) => {
    try {
      return Axios.post(API_URL + "user/updateProfile", postData).then(
        (response) => {
          return response.data.data.result;
        }
      );
    } catch (error) {
      return error;
    }
  }
);

const updateProfileSlice = createSlice({
  name: "updateProfile",
  initialState: {
    userProfileData: [],
    loading: false,
    error: "",
  },
  extraReducers: (builder) => {
    builder.addCase(updateProfileApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProfileApi.fulfilled, (state, action) => {
      state.loading = false;
      state.userProfile = action.payload;
      state.error = "";
      
    });
    builder.addCase(updateProfileApi.rejected, (state, action) => {
      //console.log(action.payload.error);
      state.loading = false;
      state.error = "invalid error";
      state.userProfile = [];
    });
  },
});
export default updateProfileSlice.reducer;
