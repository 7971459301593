import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ArtistkeletonComponent = (artist, key) => {
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
      <div className="artist-box">
        <div className="artist">
          <div className="img">
            <Skeleton height={280} />
          </div>

          <div className="content">
            <div className="h6">
              <Skeleton />
            </div>
            <div className="art-count text-muted">
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
