import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { productsApi } from "../Redux/productsSlice";
import { productsFilterApi } from "../Redux/productsFilterSlice";
import { ProductComponent } from "./Component/ProductComponent";
import { ProductSkeletonComponent } from "./Component/ProductSkeletonComponent";
import Slider from "@mui/material/Slider";

export const Product = () => {
  const dispatch = useDispatch();
  const { products, loading, totalProduct } = useSelector((state) => state.products);
  const { sizes, artists, orientations, price } = useSelector(
    (state) => state.productsFilter
  );
  const [artistsList, setArtistsList] = useState([]);
  const [orientationsList, setOrientationsList] = useState([]);
  const [sizeList, setsizeList] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search);
  const search = queryParameters.get("search");
  const [range, setRange] = React.useState([0, 10000]);
  const [sort , setSort] = useState();
  const [limit , SetLimit] = useState(100);
  const [page , SetPage] = useState(1);

  // console.log(price.min_price +"-"+price.max_price);

  useEffect(() => {
    dispatch(productsFilterApi({ search: "search" }));
    // setBanners([results]);
    dispatch(
      productsApi({
        search: search,
        artist: artistsList,
        orientation: orientationsList,
        size: sizeList,
        min_price: range ? range[0] : "",
        max_price: range ? range[1] : "",
        sort_by : sort,
        page : page,
        limit : limit,
      })
    );
    // setRange([price.min_price,price.max_price]);
  }, [artistsList, orientationsList, sizeList, range,sort,page]);

  const handleArtist = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      if (!artistsList.includes(event.target.value)) {
        setArtistsList((artistsList) => [
          ...artistsList,
          ...event.target.value,
        ]);
      }
    } else {
      setArtistsList((artistsList) =>
        artistsList.filter((item) => item !== event.target.value)
      );
    }
  };

  const handleOrientation = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      if (!orientationsList.includes(event.target.value)) {
        setOrientationsList((orientationsList) => [
          ...orientationsList,
          ...event.target.value,
        ]);
      }
    } else {
      setOrientationsList((orientationsList) =>
        orientationsList.filter((item) => item !== event.target.value)
      );
    }
  };

  const handleSize = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      if (!sizeList.includes(event.target.value)) {
        setsizeList((sizeList) => [...sizeList, ...event.target.value]);
      }
    } else {
      setsizeList((sizeList) =>
        sizeList.filter((item) => item !== event.target.value)
      );
    }
  };

  function handleChanges(event, newValue) {
    setRange(newValue);
  }

  function handleLoadMore() {
    SetPage(page + 1);
  }

  return (
    <>
      <Helmet>
        <title>Artworks</title>
        <meta name="description" content="Artworks" />
      </Helmet>
      <section className="artworks pt--60 pb--60">
        <div className="container">
          <h2 className="text-center">ARTWORKS</h2>
          <p className="text-center border-bottom pb-4">
            diverse collection of 14,000 Indian paintings, featuring acrylic on
            canvas, oil on canvas, and watercolor on paper. Discover original
            artworks in various styles, colors, and sizes. Enjoy a seamless
            online shopping experience for canvas paintings, browse through our
            wide selection, and
          </p>
          <div className="row">
            <div className="col-lg-3">
              <div className="filters">
                <h3 className="mb-4">FILTERS</h3>
                <div className="artwork-filter mb-3">
                  <h6 className="border-0">Price</h6>
                  <div className="d-flex">
                    <div className="wrapper">
                      <div className="price-input row">
                        <div className="field col-6">
                          <span className="input-min" id="minValue">
                            {range[0]}
                          </span>
                        </div>
                        <div className="field col-6 justify-content-end">
                          <span className="input-max" id="maxValue">
                            {range[1]}
                          </span>
                        </div>
                      </div>

                      <div className="range-input p-2">
                        <Slider
                          max={10000}
                          min={0}
                          value={range}
                          onChange={handleChanges}
                          valueLabelDisplay="auto"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {artists && (
                  <>
                    <div className="artwork-filter">
                      <h6
                        className="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#artist"
                      >
                        Artist <i className="fa fa-angle-down" />
                      </h6>
                      <ul className="list-unstyled " id="artist">
                        {artists.map((artist, key) => {
                          return (
                            <li key={key}>
                              <input
                                type="checkbox"
                                value={artist.filter_id}
                                name="artist[]"
                                id={"artist_" + key}
                                onChange={(checked) => handleArtist(checked)}
                              />
                              <label htmlFor={"artist_" + key}>
                                {artist.filter_name} (
                                {artist.total_count ? artist.total_count : 0})
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                )}

                {orientations && (
                  <div className="artwork-filter">
                    <h6
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#orientation"
                    >
                      Orientation <i className="fa fa-angle-down" />
                    </h6>
                    <ul className="list-unstyled " id="orientation">
                      {orientations.map((orient, key) => {
                        return (
                          <li key={key}>
                            <input
                              type="checkbox"
                              value={orient.filter_id}
                              onChange={(e) => handleOrientation(e)}
                              name="horizontal[]"
                              id={"horizontal_" + key}
                            />
                            <label htmlFor={"horizontal_" + key}>
                              {orient.filter_name} (
                              {orient.total_count ? orient.total_count : 0})
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {sizes && (
                  <div className="artwork-filter">
                    <h6
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#size"
                    >
                      Size <i className="fa fa-angle-down" />
                    </h6>
                    <ul className="list-unstyled " id="size">
                      {sizes.map((artist, key) => {
                        return (
                          <li key={key}>
                            <input
                              type="checkbox"
                              value={artist.filter_id}
                              onChange={(e) => handleSize(e)}
                              name="sizes[]"
                              id={"sizes_" + key}
                            />
                            <label htmlFor={"sizes_" + key}>
                              {artist.filter_name} (
                              {artist.total_count ? artist.total_count : 0})
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-9">
              <div className="row">
                <div
                  className="row mb-4 mt-lg-0 mt-4 align-items-center"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="col-lg-7 h6 text-muted">
                    There are {totalProduct} Arts.
                  </div>
                  <div className=" col-lg-5 col-sm-6 sortby d-flex align-items-center">
                    <div className="h6 pe-3">Sort By :</div>
                    <select className="form-select" name="sortby"   onChange={(e) => setSort(e.target.value)} >
                      <option value="relevance">Latest</option>
                      <option value="price_asc">Price, Low to High</option>
                      <option value="price_desc">Price, High to Low</option>
                      <option value="product_asc">Name, A to Z</option>
                      <option value="product_desc">Name, Z to A</option>
                    </select>
                  </div>
                </div>
              </div>

              {loading == false && products.length == 0 && (
                <div className="text-center">
                  <img
                    src={window.location.origin + "/assets/images/cart.png"}
                    alt="cart"
                    width="100px"
                  />
                  <h3>No Product Found</h3>
                </div>
              )}

              {loading == true && (
                <div className="row">
                  {Array(9)
                    .fill(1)
                    .map((card, key) => (
                      <ProductSkeletonComponent
                        key={key}
                      ></ProductSkeletonComponent>
                    ))}
                </div>
              )}
              {products && (
                <div className="row">
                  {products.map((product, key) => {
                    return (
                      <ProductComponent
                        product={product}
                        key={key}
                      ></ProductComponent>
                    );
                  })}
                  <div className="col-md-">
                  {/* <button type="button" onClick={handleLoadMore} >Load More</button> */}
                  </div>
                </div>
              
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
