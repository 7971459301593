import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ExhibitionsSkeletonComponent = (exhibitionMain,key) => {;
    const exhibition = exhibitionMain.exhibition;
  return(
    <div className="col-lg-4 col-md-6" key={key}>
      <div className="exhibition-list">
        <div className="exhibition">
          <div className="image">
          <Skeleton height={235} />
            
          </div>
          <div className="mt-3">
            <div className="exhibition-date">
            <Skeleton />
            </div>
           
              <div className="exhibition-title">
              <Skeleton />
              </div>
    
            <div className="description">
            <Skeleton />
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
