import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { updateProfileApi } from "./../../Redux/updateProfileSlice";
import { LeftMenu } from "./LeftMenu";
export const Orders = () => {
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.updateProfileData);
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.login);
  console.log("auth");
  console.log(auth);
  if (auth  == null) {
    navigate("/");
  }

  const user = JSON.parse(auth);
  const [first_name, setFirstName] = useState(user.first_name);
  const [last_name, setLastName] = useState(user.last_name);
  const [mobile, setMobile] = useState(user.mobile_no);
  const [user_id, setUserId] = useState(user.id);
  const updateProfile = (e) => {
    e.preventDefault();
    let userData = { first_name, last_name, mobile , user_id };
    dispatch(updateProfileApi(userData)).then((result) => {
      if (result.payload) {
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Dashboard" />
      </Helmet>
      <div className="breadcrumbs">
        <div className="container">
          <div className="title h1 pb-2">Dashbaord</div>
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item">
              <Link to={"/"}>Home</Link>
            </li>
            <li className="breadcrumb-item active">My Orders</li>
          </ol>
        </div>
      </div>
      <section className="account-details pt--60 pb--60">
        <div className="container">
          <h1 className="text-center mb-5">My Orders</h1>
          <div className="row">
            <div className="col-lg-3">
              <div className="account-sidebar">
              <LeftMenu active={3}></LeftMenu>
              </div>
            </div>
            <div className="col-lg-9">
          <div className="account">
            <div className="title">Orders</div>
            <div className="orders">
              <div className="filters row">
                <div className="col-md-5 col-7">
                  <div className="input-group">
                    <input
                      className="form-control"
                      name="Search"
                      id="search"
                      placeholder="search.."
                    />
                    <span className="input-group-text">
                      <span className="d-md-none d-block text-white">
                        <i className="fa fa-search" />
                      </span>
                      <span className="d-md-block d-none text-white">
                        Search
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-sm-3 col-5">
                  <div className="sort-by">
                    <select className="form-select">
                      <option selected="" disabled="">
                        Sort By
                      </option>
                      <option value={1}>3 months</option>
                      <option value={2}>6 months</option>
                      <option value={3}>1 Year</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="order-items">
                <div className="order-id">
                  <div className="row">
                    <div className="col-md-6">
                      <span className="">Order Id :</span>
                      <span>#1bx0395</span>
                    </div>
                    <div className="col-md-6">
                      <span className="pull-right order-no">
                        Order On : 25 Aug, 2023{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="item-list">
                  <div className="order-item my-2 py-4">
                    <div className="row align-items-center m-0">
                      <div className="col-lg-2 col-sm-2 col-3 text-center">
                        <img
                          src="assets/images/artworks/Chirstmas in Muscat.jpg"
                          alt="Chirstmas in Muscat"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-7 col-9">
                        <h6 className="art-name">
                          Chirstmas in Muscat <span>x (2)</span>
                        </h6>
                        <span>Acrylic on canvas, </span>
                        <span>Extra large, </span>
                      </div>
                      <div className="col-lg-2 col-sm-3 col-3 d-none">
                        <div className="price">
                          <span>total</span>
                          <div>$ 548.00</div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-3 col-3 d-none">
                        <div className="price">
                          <span>Status</span>
                          <div>Shipping</div>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center d-none">
                        <a href="order-details.html" className="view-btn">
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="order-item my-2 py-4">
                    <div className="row align-items-center m-0">
                      <div className="col-lg-2 col-sm-2 col-3 text-center">
                        <img
                          src="assets/images/artworks/Evening in the Village.jpg"
                          alt="Chirstmas in Muscat"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-7 col-9">
                        <h6 className="art-name">
                          Evening in the Village <span>x (2)</span>
                        </h6>
                        <span>Acrylic on canvas, </span>
                        <span>Extra large, </span>
                      </div>
                      <div className="col-lg-2 col-sm-3 col-3 d-none">
                        <div className="price">
                          <span>Total</span>
                          <div>$ 548.00</div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-3 col-3 d-none">
                        <div className="price">
                          <span>Status</span>
                          <div>Shipping</div>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center d-none">
                        <a href="order-details.html" className="view-btn">
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-items">
                <div className="order-id">
                  <div className="row">
                    <div className="col-md-6">
                      <span className="">Order Id :</span>
                      <span>#1bx0395</span>
                    </div>
                    <div className="col-md-6">
                      <span className="pull-right order-no">
                        Order On : 25 Aug, 2023{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="item-list">
                  <div className="order-item my-2 py-4">
                    <div className="row align-items-center m-0">
                      <div className="col-lg-2 col-sm-2 col-3 text-center">
                        <img
                          src="assets/images/artworks/Evening in the Village.jpg"
                          alt="Chirstmas in Muscat"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-7 col-9">
                        <h6 className="art-name">
                          Evening in the Village <span>x (2)</span>
                        </h6>
                        <span>Acrylic on canvas, </span>
                        <span>Extra large, </span>
                      </div>
                      <div className="col-lg-2 col-sm-3 col-3 d-none">
                        <div className="price">
                          <span>Total</span>
                          <div>$ 548.00</div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-3 col-3 d-none">
                        <div className="price">
                          <span>Status</span>
                          <div>Shipping</div>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center d-none">
                        <a href="order-details.html" className="view-btn">
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="order-item my-2 py-4">
                    <div className="row align-items-center m-0">
                      <div className="col-lg-2 col-sm-2 col-3 text-center">
                        <img
                          src="assets/images/artworks/Evening in the Village.jpg"
                          alt="Chirstmas in Muscat"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-7 col-9">
                        <h6 className="art-name">
                          Evening in the Village <span>x (2)</span>
                        </h6>
                        <span>Acrylic on canvas, </span>
                        <span>Extra large, </span>
                      </div>
                      <div className="col-lg-2 col-sm-3 col-3 d-none">
                        <div className="price">
                          <span>Total</span>
                          <div>$ 548.00</div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-3 col-3 d-none">
                        <div className="price">
                          <span>Status</span>
                          <div>Shipping</div>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center d-none">
                        <a href="order-details.html" className="view-btn">
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          </div>
        </div>
      </section>
    </>
  );
};
