import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { pageApi } from "../Redux/pageSlice";
import {useParams} from 'react-router-dom';
export const Pages = () => {
  const dispatch = useDispatch();
  const { page } = useSelector((state) => state.pages);
  const params = useParams()
  useEffect(() => {
    dispatch(pageApi(params.slug));
  }, [params]);


  return (
    <>
    {page  && 
    <>
      <Helmet>
        <title>{page.page_name}</title>
        <meta name="description" content="Pages" />
      </Helmet>

  <div className="breadcrumbs">
    <div className="container">
      <div className="title h1 pb-2">{page.page_name}</div>
      <ol className="breadcrumb m-0">
        <li className="breadcrumb-item">
          <Link to={'/'}>Home</Link>
        </li>
        <li className="breadcrumb-item active">{page.page_name}</li>
      </ol>
    </div>
  </div>
  <section className="account-details pt--60 pb--60">
 
      <div className="terms-conditions">
        <div className="vertical-space-40" />
        <div className="container">
          <div className="vertical-space-30" />
          <span dangerouslySetInnerHTML={{__html: page.page_description}} />  
        </div>
        <div className="vertical-space-60" />
      </div>
  </section>
        </>
    }
    </>
  );
};
