import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { apiResults } from "../Redux/apiSlice";
import { addCartApi } from "../Redux/addCartSlice";
import { cartQuantityNew } from "../Redux/cartCountSlice";
// Import Swiper styles
import "swiper/css/bundle";

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  WhatsappIcon,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";

export const ProductDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { data: products } = useSelector((state) => state.apidata);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [buttonText, setButtonText] = useState("Add To Cart");
  useEffect(() => {
    dispatch(apiResults("product_id/" + params.slug));
  }, [params.slug]);

  const product = products[0];
  console.log(product);
  const addToCart = (product) => {
    setButtonText("Please Wait...");
    let postData = {
      session_id: localStorage.getItem("session_id"),
      product_id: product.id,
      quantity: "1",
    };
    dispatch(addCartApi(postData)).then((result) => {
      if (result.payload) {
        //console.log(result.payload.total_cart);
        dispatch(cartQuantityNew(result.payload.total_cart));
        navigate("/cart");
      }
    });
  };

  const shareUrl = window.location.href;

  return (
    <>
      {product && (
        <>
          <Helmet>
            <title>
              {product.product_seo_title
                ? product.product_seo_title
                : product.product_name}
            </title>
            <meta
              name="description"
              content={
                product.product_seo_description
                  ? product.product_seo_description
                  : product.product_name
              }
            />
            <meta
              property="og:title"
              content={
                product.product_seo_title
                  ? product.product_seo_title
                  : product.product_name
              }
            />
            <meta
              property="og:description"
              content={
                product.product_seo_description
                  ? product.product_seo_description
                  : product.product_name
              }
            />
            <meta property="og:image" content={product.feature_image} />
          </Helmet>

          <section className="art">
            <div className="container">
              <div className="row mt-4">
                <div className="col-lg-7 art-pic">
                  <div className="slider">
                    <Swiper
                      className="mainSlider"
                      modules={[Thumbs]}
                      thumbs={{ swiper: thumbsSwiper }}
                    >
                      <SwiperSlide>
                        <img src={product.feature_image} alt="art" />
                      </SwiperSlide>
                      product.product_images.
                      {product.product_images.map((image, key) => {
                        return (
                          <SwiperSlide>
                            <img src={image.image_name} alt="art" key={key} />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={5}
                      modules={[Thumbs]}
                      watchSlidesProgress
                      onSwiper={setThumbsSwiper}
                    >
                      <SwiperSlide>
                        <img src={product.feature_image} alt="art" />
                      </SwiperSlide>

                      {product.product_images &&
                        product.product_images.map((image, key) => {
                          return (
                            <SwiperSlide>
                              <img src={image.image_name} alt="art" key={key} />
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="art-details">
                    <div className="row mob-mx-2">
                      <div className="col-lg-6 col-6">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb ">
                            <li className="breadcrumb-item">
                              <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                              <Link to="/arts">Artworks</Link>
                            </li>
                          </ol>
                        </nav>
                      </div>
                      <div className="col-lg-6  col-6 text-end">
                        {product.prev_slug && (
                          <Link
                            to={"/arts/" + product.prev_slug}
                            className="mr-5"
                          >
                            <i className="fa-solid fa-chevron-left"></i> Prev
                          </Link>
                        )}
                        {product.prev_slug && product.next_slug && (
                          <span> &nbsp;&nbsp;| &nbsp;&nbsp; </span>
                        )}
                        {product.next_slug && (
                          <Link to={"/arts/" + product.next_slug}>
                            Next <i className="fa-solid fa-chevron-right"></i>
                          </Link>
                        )}
                      </div>
                    </div>

                    <div className="name">{product.product_name}</div>
                    <div className="artist">By {product.artist_name}</div>
                    <div className="price my-3"> {product.product_price}</div>
                    {product.product_size && (
                      <div className="size mb-2">
                        <p className="product-varient">
                          <span>Size :</span> {product.product_size}
                        </p>
                      </div>
                    )}
                    {product.orientation && (
                      <div className="medium mt-2">
                        <p className="product-varient">
                          <span>Orientation :</span> {product.orientation}
                        </p>
                      </div>
                    )}
                    {product.product_medium && (
                      <div className="orientation mt-2">
                        <p className="product-varient">
                          <span>Medium :</span> {product.product_medium}
                        </p>
                      </div>
                    )}

                    <p className="product-varient">
                      <span>SKU:</span> {product.id}
                    </p>
                    <p className="product-varient">
                      <span>Status: </span>
                      {product.available == "Sold" ?  (
                        <span class="text-danger">Sold</span>
                      ) :   <span class="text-success">Available</span>}
                    </p>
                    <div className="mt-2">
                      {product.available != "Sold" && (
                        <div
                          className="btn add-to-cart"
                          onClick={() => addToCart(product)}
                        >
                          {buttonText}
                        </div>
                      )}
                      {/* <Link to="/checkout" className="btn buy-now">
                      
                      BUY NOW
                    </Link> */}
                    </div>

                    <div>
                      Share :
                      <div className="Demo__some-network">
                        <EmailShareButton
                          url={shareUrl}
                          subject={product.product_name}
                          body="body"
                          className="Demo__some-network__share-button"
                        >
                          <EmailIcon size={32} round />
                        </EmailShareButton>
                        <FacebookShareButton
                          url={shareUrl}
                          className="Demo__some-network__share-button"
                        >
                          &nbsp;
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        &nbsp;
                        <WhatsappShareButton
                          url={shareUrl}
                          title={product.product_name}
                          separator=":: "
                          className="Demo__some-network__share-button"
                        >
                          <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        &nbsp;
                        <TwitterShareButton
                          url={shareUrl}
                          title={product.product_name}
                          className="Demo__some-network__share-button"
                        >
                          <XIcon size={32} round />
                        </TwitterShareButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="features">
                <div
                  className="row"
                  data-bs-spy="scroll"
                  data-bs-target=".nav-pills"
                  data-offset={50}
                >
                  <div className="col-lg-3">
                    <nav className="">
                      <ul className="navbar-nav d-block">
                        <li className="nav-item">
                          <a
                            href="#art-info"
                            className="nav-link active"
                            onclick="changeTab('art-info')"
                          >
                            Art Info
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#description"
                            className="nav-link"
                            onclick="changeTab('description')"
                          >
                            Description
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#shiping-info"
                            className="nav-link"
                            onclick="changeTab('shiping-info')"
                          >
                            Shipping Info
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#artist"
                            className="nav-link"
                            onclick="changeTab('artist')"
                          >
                            About Artist
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="col-lg-9">
                    <div className="tab-content">
                      <div id="art-info" className="tab-box">
                        <div className="h3 mb-3">Art Info</div>
                        <div className="content ps-4 mb-3">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: product.product_features,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div id="description" className="tab-box">
                        <div className="h3 mb-3">Description</div>
                        <div className="content ps-4 mb-3">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: product.product_description,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div id="shiping-info" className="tab-box">
                        <div className="h3 mb-3">Shiping Info</div>
                        <div className="content ps-4 mb-3">
                          This is an exclusive handmade painting by Community
                          Artists Group. Community Artists Group is the group of
                          under-privileged artists. They have a very good
                          artistic hand and create great handmade paintings,
                          however they lack access to online audience. Fizdi
                          works extensively with such artists, and makes sure
                          that their art gets visible to online buyers.
                          Community Artists Group paintings are offered at very
                          economical prices. Most of them are also offered in
                          custom sizes, where artist recreates them by hand
                          specially for you.
                        </div>
                      </div>
                      <div id="artist" className="tab-box">
                        <div className="h3 mb-3">About Artist</div>
                        <div className="artist-profile content ps-4 mb-3">
                          <div className="row align-items-center">
                            <a href="artist.html" style={{ width: "auto" }}>
                              <img
                                src={product.artist_image}
                                alt="artist"
                                className="artist"
                                style={{ width: "50px", height: "50px" }}
                              />
                            </a>
                            <div className="col-lg-10">
                              <a href="artist.html">
                                <div className="h5"> {product.artist_name}</div>
                                <div className="text-muted">
                                  {product.artist_location}
                                </div>
                              </a>
                            </div>
                          </div>
                          <p className="artists-details mt-3">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: product.artist_description,
                              }}
                            ></div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
