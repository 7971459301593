import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { getCartApi } from "../Redux/cartSlice";
import { deleteCartApi } from "../Redux/deleteCartSlice";
import { cartQuantityUpdate, cartQuantityDelete, cartQuantityNew } from "../Redux/cartCountSlice";

export const Cart = () => {
  const dispatch = useDispatch();

  const { cart, taxPrice, shippingPrice, totalPrice, subTotal, loading } =
    useSelector((state) => state.cart);
  const { user, error, auth } = useSelector((state) => state.login);

  useEffect(() => {
    dispatch(getCartApi(localStorage.getItem("session_id")));
    // setBanners([results]);
    // if(loading == false && cart.length > 0 ){
    //   dispatch(cartQuantityNew(cart.length));
    // }
  }, []);
 
  const cartDelete = (product_id) => {
    // const cartNew = cart.filter((_, i) => i !== product_id);
    let postData = {
      session_id: localStorage.getItem("session_id"),
      product_id: cart[product_id].product_id,
      quantity: "1",
    };

    dispatch(deleteCartApi(postData)).then((result) => {
      if (result.payload) {
        //console.log(result.payload.total_cart);
          dispatch(cartQuantityNew(result.payload.total_cart));
          dispatch(getCartApi(localStorage.getItem("session_id")));
      }
    });

  };

  return (
    <>
      <Helmet>
        <title>Cart</title>
        <meta name="description" content="Cart" />
      </Helmet>
      <section className="cart-details pt--60 pb--60">
        {cart.length > 0 && (
          <div className="container">
            <h3 className="mb-5">Shoping Cart ( {cart.length} Items)</h3>
            <div className="row">
              <div className="col-lg-12">
                <div className="cart-header border-bottom d-md-block d-none">
                  <div className="row">
                    <div className="col-lg-8 col-md-7">
                      <div className="row">
                        <div className="col-lg-3 col-md-3">
                          <div className="arts">Products</div>
                        </div>
                        <div className="col-lg-8 col-md-9">
                          <div className="art-info">Product Info</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-2">
                      <div className="price">Price</div>
                    </div>
                    <div className="col-lg-2 col-md-2"></div>
                  </div>
                </div>

                <div className="cart-body">
                  {cart.map((list, key) => {
                    return (
                      <div className="cart-product" key={key}>
                        <div className="row align-items-center">
                          <div className="col-lg-8 col-md-7 mb-3">
                            <div className="row align-items-center">
                              <div className="col-lg-3 col-md-3 col-sm-2 mb-3">
                                <div className="art-image">
                                  <a href="details-page.html">
                                    <img
                                      src={list.feature_image}
                                      alt={list.product_name}
                                    />
                                  </a>
                                </div>
                              </div>
                              <div className="col-lg-9 col-md-9 col-sm-10">
                                <div className="art-info">
                                  <div className="art-name">
                                    <h5> {list.product_name}</h5>
                                  </div>
                                  <div className="size">
                                    <span className="size">
                                      {list.product_size}
                                      {list.orientation_name
                                        ? " ," + list.orientation_name
                                        : ""}
                                      {list.medium_name
                                        ? " ," + list.medium_name
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-2 col-sm-4 col-6 mb-3">
                            <div className="price"> {list.product_price}</div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-md-12 col-sm-4 mb-3 text-lg-center text-md-end delete">
                            <button
                              className="btn"
                              onClick={(e) => cartDelete(key)}
                              type="button"
                            >
                              <i className="fa fa-trash pe-2" />
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="cart-total">
                <div className="sub-total">
                  <span>Sub Total</span>:
                  <span className="total-price">{subTotal}</span>
                </div>
                <p>Tax included. Shipping calculated at checkout.</p>
                <Link to={"/checkout"} className="btn-cart">
                  Check Out
                </Link>
              </div>
            </div>
          </div>
        )}
        {cart.length == 0 && loading == false && (
          <>
            <div className="container">
              <div className="text-center">
                <img
                  src={window.location.origin + "/assets/images/cart.png"}
                  alt="cart"
                  width="100px"
                />
                <h3>Your cart is empty!</h3>
                <p className="mt-4">
                  <Link to={"/"} className="btn-cart-empty ">
                    Shop Now
                  </Link>
                </p>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};
