import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const exhibitionsApi = createAsyncThunk(
  "get/getExhibitions",
  async (path, method = "get") => {
    return Axios({
      method: "get",
      url: API_URL + "exhibitions",
    }).then((response) => {   
      return response.data.data.result;
    });
  }
);

const exhibitionsSlice = createSlice({
  name: "exhibitions",
  initialState: {
    exhibitions: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(exhibitionsApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(exhibitionsApi.fulfilled, (state, action) => {
      state.loading = false;
      state.exhibitions = action.payload;
    });
    builder.addCase(exhibitionsApi.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default exhibitionsSlice.reducer;

