import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const pageApi = createAsyncThunk("get/get", async (path) => {
  return Axios({
    method: "get",
    url: API_URL + "pages/" + path,
  }).then((response) => {
   
    return response.data.data.result;
  });
});

const pagesSlice = createSlice({
  name: "pages",
  initialState: {
    page: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(pageApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(pageApi.fulfilled, (state, action) => {
      state.loading = false;
     
      state.page = action.payload;
    });
    builder.addCase(pageApi.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default pagesSlice.reducer;
