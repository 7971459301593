import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const exhibitionsDetailsApi = createAsyncThunk(
  "get/getExhibitionsDetails",
  async (slug) => {
    return Axios({
      method: "get",
      url: API_URL + "exhibitions_details/" + slug,
    }).then((response) => {   
      return response.data.data.result;
    });
  }
);

const exhibitionsDetailsSlice = createSlice({
  name: "exhibitionDetail",
  initialState: {
    exhibitionDetail: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(exhibitionsDetailsApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(exhibitionsDetailsApi.fulfilled, (state, action) => {
      state.loading = false;
      state.exhibitionDetail = action.payload;
    });
    builder.addCase(exhibitionsDetailsApi.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default exhibitionsDetailsSlice.reducer;

