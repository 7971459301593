import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { registerApi } from "../Redux/registerSlice";

export const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading, error, auth, success } = useSelector(
    (state) => state.register
  );

  //   if (auth) {
  //     navigate("/dashboard");
  //   }
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const registerHandle = (e) => {
    e.preventDefault();
    let userCredential = {
      mobile: mobile,
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      confirm_password: confirmPassword,
    };
    console.log(userCredential);
    dispatch(registerApi(userCredential));

    console.log("-----");
    console.log(error);
    console.log(success);
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Login" />
      </Helmet>
      <>
        <div className="breadcrumbs">
          <div className="container">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">Register</li>
            </ol>
          </div>
        </div>
        <section className="register pt-5 pb--60">
          <div className="container">
            <div className="register-user">
              <form onSubmit={registerHandle} id="customer_register">
                <h5 className="title mb-3 text-center">REGISTER</h5>
                <p className="mb-4">
                  Please Register using account detail bellow.
                </p>
                {error && (
                  <div
                    class="alert alert-warning alert-dismissible fade show"
                    role="alert"
                  >
                    <div dangerouslySetInnerHTML={{ __html: error }}></div>
                  </div>
                )}
                {success && (
                  <div
                    class="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    {success}
                  </div>
                )}

                <div className="mb-3">
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="customer-name"
                        placeholder="First Name"
                        id="CustomerName"
                        className="form-control"
                        autoCorrect="off"
                        autoCapitalize="off"
                        autofocus=""
                        fdprocessedid="ylebej"
                        required
                        onChange={(e) => setfirstName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="customer-name"
                        placeholder="Last Name"
                        id="CustomerName"
                        className="form-control"
                        autoCorrect="off"
                        autoCapitalize="off"
                        autofocus=""
                        fdprocessedid="ylebesj"
                        required
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    name="customer-mobile"
                    placeholder="Mobile"
                    id="CustomerMobile"
                    className="form-control"
                    autoCorrect="off"
                    autoCapitalize="off"
                    autofocus=""
                    fdprocessedid="ylebej"
                    required
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    name="customer-email"
                    placeholder="Email"
                    id="CustomerEmail"
                    className="form-control"
                    autoCorrect="off"
                    autoCapitalize="off"
                    autofocus=""
                    fdprocessedid="ylebej"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    defaultValue=""
                    name="customer-password"
                    placeholder="Password"
                    id="CustomerPassword"
                    className="form-control"
                    fdprocessedid="1kxvvf"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    defaultValue=""
                    name="customer-password"
                    placeholder="Re-enter Password"
                    id="CustomerPassword"
                    className="form-control"
                    fdprocessedid="1kxvvf"
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div className="register-button">
                  <input
                    type="submit"
                    className="btn btn-submit"
                    defaultValue={
                      loading == true ? "Please Wait..." : "Sign Up"
                    }
                  />
                </div>
                <div className="register my-4">
                  Already have an account?
                  <Link to={"/login"}> Login account</Link>
                </div>
                {/* <div className="or py-2">
          <hr />
          <div className="text"> OR Register with</div>
        </div> */}
              </form>
              {/* <div className="row mt-3">
        <div className="col-sm-6">
          <a href="" className="facebook mb-3">
            <i className="fa-brands fa-facebook-f" />
            Sign Up with Facebook
          </a>
        </div>
        <div className="col-sm-6">
          <a href="" className="google mb-3">
            <i className="fa-brands fa-google" />
            Sign Up with Google
          </a>
        </div>
      </div> */}
            </div>
          </div>
        </section>
      </>
    </>
  );
};
