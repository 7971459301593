import { configureStore } from "@reduxjs/toolkit";
// import  userReducer from './userSlice';
import productsReducer from "./productsSlice";
import bannerReducer from "./bannerSlice";
import apiReducer from "./apiSlice";
import artistReducer from "./artistSlice";
import artistDetailsReducer from "./artistDetailsSlice";
import loginReducer from "./loginSlice";
import registerReducer from "./registerSlice";
import cartReducer from "./cartSlice";
import productsFilterReducer from "./productsFilterSlice";
import pageReducer from "./pageSlice";
import settingReducer from "./settingSlice";
import exhibitionsReducer from "./exhibitionsSlice";
import pressReleaseReducer from "./pressReleaseSlice";
import updateProfileSlice from "./updateProfileSlice";
import homeReducer from "./homeSlice";
import exhibitionsDetailsReducer from "./exhibitionsDetailsSlice";
import addCartReducer from "./addCartSlice";
import deleteCartReducer from "./addCartSlice";
import makeOrderReducer from "./makeOrderSlice";
import updateOrderReducer from "./udpateOrderSlice";
import orderDetailsReducer from "./orderDetailsSlice";
import cartCountReducder from "./cartCountSlice";

const store = configureStore({
  reducer: {
    // user : userReducer,
    home :  homeReducer,
    products: productsReducer,
    banner: bannerReducer,
    apidata: apiReducer,
    artist: artistReducer,
    login: loginReducer,
    register : registerReducer,
    productsFilter: productsFilterReducer,
    pages: pageReducer,
    artistDetails: artistDetailsReducer,
    setting: settingReducer,
    exhibition: exhibitionsReducer,
    pressRelease: pressReleaseReducer,
    updateProfileData: updateProfileSlice,
    exhibitionsDetails: exhibitionsDetailsReducer,
    cart : cartReducer ,
    addCart: addCartReducer,
    deleteCart : deleteCartReducer,
    makeOrder : makeOrderReducer,
    updateOrder: updateOrderReducer,
    orderDetails : orderDetailsReducer,
    cartCount : cartCountReducder

  },
});

export default store;
