import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export const LeftMenu = ({ active }) => {
  return (
<ul className="list-unstyled">
                  <li className={active == 1 ? "active" : ""}>
                    <Link to={"/dashboard"}>Dashboard</Link>
                  </li>
                  <li className={active == 2 ? "active" : ""}>
                    <Link to={"/address"}>Address</Link>
                  </li>
                  <li className={active == 3 ? "active" : ""} >
                    <Link to={"/orders"}>Orders</Link>
                  </li>
                  <li className={active == 4 ? "active" : ""}>
                    <Link to={"/change-password"}>Change Password</Link>
                  </li>
                  <li className={active == 5 ? "active" : ""}>
                    <Link to={"/logout"}>Logout</Link>
                  </li>
                </ul>
  );
}