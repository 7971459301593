import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const homeApi = createAsyncThunk(
  "get/getHome",
  async () => {
    try {
      return Axios.get(API_URL + "home").then((response) => {
        return response.data.data;
      });
    } catch (error) {
      return error
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState: {
    banners : [],
    exhibitions : [],
    pressReleases : [],
    products : [],
    artists : [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(homeApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(homeApi.fulfilled, (state, action) => {
      state.loading = false;
      state.banners = action.payload.banner;
      state.artists = action.payload.artist;
      state.pressReleases = action.payload.press_release;
      state.products = action.payload.product;
      state.exhibitions = action.payload.exhibitions;
    });
    builder.addCase(homeApi.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default homeSlice.reducer;

