import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";
//import { json } from "react-router-dom";

export const makeOrderApi = createAsyncThunk(
  "order/makeOrder",
  async (postData) => {
    try {
      return Axios.post(API_URL + "order", postData).then((response) => {
        return response.data;
      });
    } catch (error) {
      return error;
    }
  }
);

const makeOrderSlice = createSlice({
  name: "makeOrder",
  initialState: {
    orderResult: [],
    loading: false,
    error: "",
  },
  extraReducers: (builder) => {
    builder.addCase(makeOrderApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(makeOrderApi.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload.data);
      state.orderResult = action.payload.data;
    });
    builder.addCase(makeOrderApi.rejected, (state, action) => {
      //console.log(action.payload.error);
      state.loading = false;
      state.error = "invalid username/password";
    });
  },
});
export default makeOrderSlice.reducer;
