import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";
//import { json } from "react-router-dom";

export const loginApi = createAsyncThunk("post/postLogin", async (postData) => {
  try {
    return Axios.post(API_URL + "user/login", postData).then((response) => {
      return response.data.data.result;
    });
  } catch (error) {
    return error
  }
});

const loginSlice = createSlice({
  name: "login",
  initialState: {
    user: [],
    loading: false,
    error: "",
    auth : localStorage.getItem("user")
  },
  extraReducers: (builder) => {
    builder.addCase(loginApi.pending, (state) => {
      state.loading = true;
      localStorage.removeItem("user");
    });
    builder.addCase(loginApi.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = "";
      localStorage.setItem("user", JSON.stringify(action.payload));

    });
    builder.addCase(loginApi.rejected, (state, action) => {
      //console.log(action.payload.error);
      state.loading = false;
      state.error = "invalid username/password";
      state.user = [];
      localStorage.removeItem("user");
    });
  },
});
export default loginSlice.reducer;
