import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const addCartApi = createAsyncThunk(
  "get/addCart",
  async (postdata) => {
    try {
      return Axios.post(API_URL + "cart/add",postdata).then((response) => {
        return response.data.data;
      });
    } catch (error) {
      return error;
    }
  }
);

const addCartSlice = createSlice({
  name: "cart",
  initialState: {
    result : [],
  },
  extraReducers: (builder) => {
    builder.addCase(addCartApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCartApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action.payload.result;

    });
    builder.addCase(addCartApi.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default addCartSlice.reducer;
