import React, { useEffect, useState } from "react";
import { Header } from './Header'
import { Footer } from './Footer'
import { Routers } from '../Routers/Routers'
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { settingApi } from "../Redux/settingSlice";

export const Layout = () => {
  const { setting } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getUsers());
    dispatch(settingApi());
  }, []);


  return (
    <>
        <Header settings={setting}></Header>
        <Routers></Routers>
        <Footer settings={setting} ></Footer>
    </>
  )
}
