import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const settingApi = createAsyncThunk("get/getSetting", async (path) => {
  return Axios({
    method: "get",
    url: API_URL + "setting",
  }).then((response) => {
   
    return response.data.data.result;
  });
});

const settingSlice = createSlice({
  name: "setting",
  initialState: {
    setting: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(settingApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(settingApi.fulfilled, (state, action) => {
      state.loading = false;
      state.setting = action.payload;
    });
    builder.addCase(settingApi.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default settingSlice.reducer;
