import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { exhibitionsDetailsApi } from "../Redux/exhibitionsDetailsSlice";
import { exhibitionsApi } from "../Redux/exhibitionsSlice";

export const ExhibitionDetails = () => {
  const dispatch = useDispatch();
  const { exhibitionDetail } = useSelector((state) => state.exhibitionsDetails);
  const { exhibitions  } = useSelector((state) => state.exhibition);

  const params = useParams();

  useEffect(() => {
    dispatch(exhibitionsDetailsApi(params.slug));
    dispatch(exhibitionsApi());
  }, [params.slug]);
  //console.log(exhibitions);
  return (
    <>
      <Helmet>
        <title>Artist</title>
        <meta name="description" content="Artworks" />
      </Helmet>
      {exhibitionDetail && (
        <section className="exhibition-details pt--60 pb--60">
          <div className="container">
            <div className="title">
              <div className="exhibition-title">
                {exhibitionDetail.exhibition_title}
              </div>
              <div className="exhibition-date">
                {exhibitionDetail.exhibition_start_date} -
                {exhibitionDetail.exhibition_end_date}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 about-exhibition">
                <div className="image pb-4">
                  <img
                    src={exhibitionDetail.exhibition_image}
                    alt="{exhibition.exhibition_title }"
                  />
                </div>
                <section className="exhibition-fulldescription">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: exhibitionDetail.exhibition_short_description,
                    }}
                  ></div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: exhibitionDetail.exhibition_full_description,
                    }}
                  ></div>
                </section>
                <div className="share border-top border-bottom mt-4 py-2">
                  <ul className="navbar list-unstyled justify-content-center gap-3 m-0">
                    <li className="nav-item">
                      <a href="" className="f">
                        <i className="fa-brands fa-facebook-f pe-3" />
                        Facebook
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="" className="t">
                        <i className="fa-brands fa-twitter pe-3" />
                        Twitter
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="" className="i">
                        <i className="fa-brands fa-instagram pe-3" />
                        Instagram
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="" className="l">
                        <i className="fa fa-link pe-3" />
                        Link
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
              {exhibitions && (
                <aside className="side-bar">
                  <div className="upcoming-exhibition mb-5">
                    <div className="h5 border-bottom pb-3 mb-4">
                      Upcoming Exhibition
                    </div>
                    
                      <ul className="">
                        {exhibitions.map((exhibi, key) => {
                          return (
                            <li key={key}>
                              <Link
                                to={"/exhibitions/" + exhibi.exhibition_slug}
                              >
                                {exhibi.exhibition_title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                 

                  </div>
               
                  {/* <div className="featured-art">
                  <div className="h5 border-bottom pb-3 mb-4">Featured Art</div>
                  <div className="slider">
                    <div className="owl-carousel featured-art-carousel">
                      <div className="slider-card">
                        <a href="">
                          <div className="art">
                            <img src="assets/images/artworks/Omani Village.jpg" />
                            <div className="art-name">
                              <div className="">Omani Village</div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="slider-card">
                        <a href="">
                          <div className="art">
                            <img src="assets/images/artworks/Transparency 2.jpg" />
                            <div className="art-name">
                              <div className="">Transparency 2</div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="slider-card">
                        <a href="">
                          <div className="art">
                            <img src="assets/images/artworks/Fall.jpg" />
                            <div className="art-name">
                              <div className="">Fall</div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                </aside>
                   )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
