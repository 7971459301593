import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ProductSkeletonComponent = (key) => {
  return (
    <div className="col-lg-4" key={key}>
      <div className="artwork-box">
        <div className="img">
          <Skeleton height={230} />
        </div>
        <div className="content">
          <Skeleton />
          <Skeleton />
        </div>
      </div>
    </div>
  );
};
