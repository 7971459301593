import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const cartCountSlice = createSlice({
  name: "cartCount",
  initialState: {
    totalCart: localStorage.getItem("cartCount")
      ? localStorage.getItem("cartCount")
      : 0,
  },
  reducers: {
    cartQuantityAdd: (state, action) => {
        console.log(parseInt(state.totalCart)+ parseInt(action.payload) );
      var newCartCount = parseInt(state.totalCart) + parseInt(action.payload);
      localStorage.setItem("cartCount", newCartCount);
      state.totalCart = localStorage.getItem("cartCount");
    },
    cartQuantityDelete: (state, action) => {
      var newCartCount = parseInt(state.totalCart) - parseInt(action.payload);
      localStorage.setItem("cartCount", newCartCount);
      state.totalCart = newCartCount;
    },
    cartQuantityUpdate: (state, action) => {
      var newCartCount = parseInt(state.totalCart) +  parseInt(action.payload);
      localStorage.setItem("cartCount", newCartCount);
      state.totalCart = newCartCount;
    },
    cartQuantityNew: (state, action) => {
      var newCartCount = action.payload;
      localStorage.setItem("cartCount", newCartCount);
      state.totalCart = newCartCount;
    },
  },
});
export const {
  cartQuantityUpdate,
  cartQuantityAdd,
  cartQuantityDelete,
  cartQuantityNew,
} = cartCountSlice.actions;
export default cartCountSlice.reducer;
