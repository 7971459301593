import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { getCartApi } from "../Redux/cartSlice";
import { deleteCartApi } from "../Redux/deleteCartSlice";
import { makeOrderApi } from "../Redux/makeOrderSlice";
import { updateOrderApi } from "../Redux/udpateOrderSlice";
import { loginApi } from "../Redux/loginSlice";
import { cartQuantityNew } from "../Redux/cartCountSlice";
export const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cart, taxPrice, shippingPrice, totalPrice, subTotal, loading } =
    useSelector((state) => state.cart);
  const { user, error, auth } = useSelector((state) => state.login);

  const { orderResult } = useSelector((state) => state.makeOrder);
  const { result } = useSelector((state) => state.deleteCart);

  const [loginStage, setLoginStage] = useState(1);
  const [deliveryStage, setDeliveryStage] = useState(0);
  const [shippingStage, setShippingStage] = useState(0);
  const [paymentStage, setPaymentStage] = useState(0);
  const [alreadyLogin, setAlreadyLogin] = useState(0);

  const [email, setEmail] = useState();

  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("Oman");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [phone, setPhone] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [orderId, setOrderId] = useState("");
  const [userId, setUserId] = useState("");
  useEffect(() => {
    dispatch(getCartApi(localStorage.getItem("session_id")));

    // setLoginStage(2);
    // setDeliveryStage(1);

    // if(cart == "undefine"){
    //   navigate("/" );
    // }
    // setBanners([results]);
    setLoginStage(2);
    setDeliveryStage(1);

    if (auth) {
      setLoginStage(2);
      setDeliveryStage(1);
    } else {
      setAlreadyLogin(1);
    }
  }, []);

  if (loading == false && cart.length == 0) {
    navigate("/");
  }

  //console.log(cart);
  const onSubmitHandler = (event) => {
    event.preventDefault();

    let userCredential = { email, password };
    dispatch(loginApi(userCredential)).then((result) => {
      if (result.payload) {
        // console.log(result.payload.id);
        setUserId(result.payload.id);
        setDeliveryStage(1);
        setLoginStage(0);
      }
    });
  };

  const makeOrderSubmit = (event) => {
    event.preventDefault();
    //console.log(auth);
    let userData = {
      email: billingEmail,
      first_name: firstName,
      last_name: lastName,
      address: address,
      city,
      state,
      country,
      pincode,
      landmark,
      phone_no: phone,
      user_id: auth ? JSON.parse(auth).id : userId,
      session_id: localStorage.getItem("session_id"),
    };
    dispatch(makeOrderApi(userData)).then((result) => {
      if (result.payload) {
        setOrderId(result.payload.data.order_id);
        setDeliveryStage(2);
        setPaymentStage(1);
      }
    });
  };

  const paymentConfirm = (event) => {
    event.preventDefault();
    let orderData = {
      order_id: orderId,
      payment_status: "1",
      payment_type: "1",
      session_id: localStorage.getItem("session_id"),
    };
    dispatch(updateOrderApi(orderData)).then((result) => {
      if (result.payload) {
        dispatch(cartQuantityNew(0));
        navigate("/confirmation/" + result.payload.order_no);
      }
    });
  };

  // function handleShipping() {
  //   setShippingStage(0);
  //   setPaymentStage(1);
  // }

  // const cartDelete = (product_id) => {
  //   console.log(cart[product_id].product_id);
  //   // const cartNew = cart.filter((_, i) => i !== product_id);
  //   let postData = {
  //     session_id: "1",
  //     product_id: cart[product_id].product_id,
  //     quantity: "1",
  //   };
  //   dispatch(deleteCartApi(postData));
  //   dispatch(getCartApi(1));
  // };

  const handleAlreadyUser = (event) => {
    setLoginStage(1);
    setDeliveryStage(2);
    setAlreadyLogin(2);
  };
  return (
    <>
      <Helmet>
        <title>Checkout </title>
        <meta name="description" content="Artworks" />
      </Helmet>
      <section className="checkout pt--60 pb--60">
        <div className="container">
          <h2 className="mb-5">CHECKOUT</h2>
          <div className="row">
            <div className="col-lg-7">
              <div className="checkout-details">
                {loginStage == 1 && (
                  <div className="user-info">
                    <div className="head">
                      <div className="title"> Login / Register </div>
                      {/* <div className="content">customer@gmail.com</div> */}
                    </div>
                    <div className="checkout-data">
                      <form onSubmit={onSubmitHandler}>
                        {error && (
                          <div
                            class="alert alert-warning alert-dismissible fade show"
                            role="alert"
                          >
                            <strong>Error</strong> {error}
                          </div>
                        )}

                        <div className="mb-4">
                          <label>Email address</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                            className="form-control"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                          />
                        </div>
                        <div className="mb-4">
                          <label>Password</label>
                          <input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Enter your password"
                            className="form-control"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                          />
                        </div>
                        <div className="row m-0">
                          {/*   <div className="form-check mb-sm-4 mb-2 col-md-6 col-sm-6">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="remember"
                              />
                              Email me News and Offers
                            </label> 
                          </div>*/}

                          <div className="col-12 p-0">
                            <button
                              type="submit"
                              className="btn-continue btn col-sm-5"
                            >
                              Continue
                            </button>

                            <div className="col-md-6 col-sm-6 mb-4 mt-2">
                              You don't have an account?
                              <Link to="/login">Register</Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>{" "}
                  </div>
                )}

                {alreadyLogin == 1 && (
                  <div className="delivery-info">
                    <div className="head">
                      <div className="title">
                        {" "}
                        Already have an account?{" "}
                        <Link
                          className=""
                          onClick={(e) => handleAlreadyUser(e)}
                        >
                          Click here to login
                        </Link>
                      </div>
                    </div>
                  </div>
                )}

                <div className="delivery-info">
                  <div className="head">
                    <div className="title"> Delivery Address</div>
                    {/* <div className="content">
                  131 Milo Lodge, Lake Louie, NH 15784
                </div> */}
                  </div>
                  {deliveryStage == 1 && (
                    <div className="checkout-data" id="delivery-info">
                      <form onSubmit={makeOrderSubmit}>
                        <div className="row">
                          <div className="col-sm-6 mb-3">
                            <label>First Name</label>
                            <input
                              type="text"
                              name="first_name"
                              id="first_name"
                              placeholder="Enter your first name"
                              className="form-control"
                              onChange={(e) => setFirstName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-sm-6 mb-3">
                            <label>Last Name</label>
                            <input
                              type="text"
                              name="last_name"
                              id="last_name"
                              placeholder="Enter your last name"
                              className="form-control"
                              onChange={(e) => setLastName(e.target.value)}
                              required
                            />
                          </div>

                          <div className="col-sm-12 mb-3">
                            <label>Address</label>
                            <input
                              type="text"
                              name="address"
                              id="address"
                              placeholder="Address"
                              className="form-control"
                              required
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>

                          <div className="col-sm-4 mb-3">
                            <label>City</label>
                            <input
                              type="text"
                              name="city"
                              id="city"
                              placeholder="City"
                              className="form-control"
                              onChange={(e) => setCity(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-sm-4 mb-3">
                            <label>State</label>
                            <input
                              type="text"
                              name="state"
                              id="state"
                              placeholder="State"
                              className="form-control"
                              onChange={(e) => setState(e.target.value)}
                              required
                            />
                          </div>

                          <div className="col-sm-4 mb-3">
                            <label>Country / Region</label>
                            <select
                              className="form-select"
                              name="country"
                              onChange={(e) => setCountry(e.target.value)}
                              required
                            >
                              <option value="Afghanistan">Afghanistan</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="American Samoa">
                                American Samoa
                              </option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antartica">Antarctica</option>
                              <option value="Antigua and Barbuda">
                                Antigua and Barbuda
                              </option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Aruba">Aruba</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bosnia and Herzegowina">
                                Bosnia and Herzegowina
                              </option>
                              <option value="Botswana">Botswana</option>
                              <option value="Bouvet Island">
                                Bouvet Island
                              </option>
                              <option value="Brazil">Brazil</option>
                              <option value="British Indian Ocean Territory">
                                British Indian Ocean Territory
                              </option>
                              <option value="Brunei Darussalam">
                                Brunei Darussalam
                              </option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">
                                Cayman Islands
                              </option>
                              <option value="Central African Republic">
                                Central African Republic
                              </option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Christmas Island">
                                Christmas Island
                              </option>
                              <option value="Cocos Islands">
                                Cocos (Keeling) Islands
                              </option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Congo">
                                Congo, the Democratic Republic of the
                              </option>
                              <option value="Cook Islands">Cook Islands</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cota D'Ivoire">
                                Cote d'Ivoire
                              </option>
                              <option value="Croatia">
                                Croatia (Hrvatska)
                              </option>
                              <option value="Cuba">Cuba</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">
                                Czech Republic
                              </option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">
                                Dominican Republic
                              </option>
                              <option value="East Timor">East Timor</option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">
                                Equatorial Guinea
                              </option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Falkland Islands">
                                Falkland Islands (Malvinas)
                              </option>
                              <option value="Faroe Islands">
                                Faroe Islands
                              </option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="France Metropolitan">
                                France, Metropolitan
                              </option>
                              <option value="French Guiana">
                                French Guiana
                              </option>
                              <option value="French Polynesia">
                                French Polynesia
                              </option>
                              <option value="French Southern Territories">
                                French Southern Territories
                              </option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Gibraltar">Gibraltar</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-Bissau">
                                Guinea-Bissau
                              </option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Heard and McDonald Islands">
                                Heard and Mc Donald Islands
                              </option>
                              <option value="Holy See">
                                Holy See (Vatican City State)
                              </option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran">
                                Iran (Islamic Republic of)
                              </option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Democratic People's Republic of Korea">
                                Korea, Democratic People's Republic of
                              </option>
                              <option value="Korea">Korea, Republic of</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Lao">
                                Lao People's Democratic Republic
                              </option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libyan Arab Jamahiriya">
                                Libyan Arab Jamahiriya
                              </option>
                              <option value="Liechtenstein">
                                Liechtenstein
                              </option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macau">Macau</option>
                              <option value="Macedonia">
                                Macedonia, The Former Yugoslav Republic of
                              </option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">
                                Marshall Islands
                              </option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mayotte">Mayotte</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia">
                                Micronesia, Federated States of
                              </option>
                              <option value="Moldova">
                                Moldova, Republic of
                              </option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Netherlands Antilles">
                                Netherlands Antilles
                              </option>
                              <option value="New Caledonia">
                                New Caledonia
                              </option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Niue">Niue</option>
                              <option value="Norfolk Island">
                                Norfolk Island
                              </option>
                              <option value="Northern Mariana Islands">
                                Northern Mariana Islands
                              </option>
                              <option value="Norway">Norway</option>
                              <option value="Oman" selected>
                                Oman
                              </option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">
                                Papua New Guinea
                              </option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Pitcairn">Pitcairn</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russia">Russian Federation</option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Kitts and Nevis">
                                Saint Kitts and Nevis
                              </option>
                              <option value="Saint LUCIA">Saint LUCIA</option>
                              <option value="Saint Vincent">
                                Saint Vincent and the Grenadines
                              </option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">
                                Sao Tome and Principe
                              </option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">
                                Slovakia (Slovak Republic)
                              </option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">
                                Solomon Islands
                              </option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="South Georgia">
                                South Georgia and the South Sandwich Islands
                              </option>
                              <option value="Span">Spain</option>
                              <option value="SriLanka">Sri Lanka</option>
                              <option value="St. Helena">St. Helena</option>
                              <option value="St. Pierre and Miguelon">
                                St. Pierre and Miquelon
                              </option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Svalbard">
                                Svalbard and Jan Mayen Islands
                              </option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syria">
                                Syrian Arab Republic
                              </option>
                              <option value="Taiwan">
                                Taiwan, Province of China
                              </option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania">
                                Tanzania, United Republic of
                              </option>
                              <option value="Thailand">Thailand</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">
                                Trinidad and Tobago
                              </option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Turks and Caicos">
                                Turks and Caicos Islands
                              </option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">
                                United Arab Emirates
                              </option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="United States">
                                United States
                              </option>
                              <option value="United States Minor Outlying Islands">
                                United States Minor Outlying Islands
                              </option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Vietnam">Viet Nam</option>
                              <option value="Virgin Islands (British)">
                                Virgin Islands (British)
                              </option>
                              <option value="Virgin Islands (U.S)">
                                Virgin Islands (U.S.)
                              </option>
                              <option value="Wallis and Futana Islands">
                                Wallis and Futuna Islands
                              </option>
                              <option value="Western Sahara">
                                Western Sahara
                              </option>
                              <option value="Yemen">Yemen</option>
                              <option value="Serbia">Serbia</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                          </div>

                          <div className="col-sm-6 mb-3">
                            <label>Pincode</label>
                            <input
                              type="text"
                              name="pincode"
                              id="pincode"
                              placeholder="Pincode"
                              className="form-control"
                              onChange={(e) => setPincode(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-sm-6 mb-3">
                            <label>Landmark</label>
                            <input
                              type="text"
                              name="landmark"
                              id="landmark"
                              placeholder="Landmark"
                              className="form-control"
                              onChange={(e) => setLandmark(e.target.value)}
                            />
                          </div>
                          <div className="col-sm-6 mb-3">
                            <label>Phone Number</label>
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              placeholder="Phone No"
                              className="form-control"
                              onChange={(e) => setPhone(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-sm-6 mb-3">
                            <label>Email</label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email"
                              className="form-control"
                              onChange={(e) => setBillingEmail(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="row m-0">
                          <button type="submit" className="btn-continue btn">
                            Continue
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
                {/* <div className="delivery-info shipping-address">
                    <div className="head">
                      <div className="title">Shipping Address </div>
                 
                    </div>
                    {shippingStage && (
                      <div className="checkout-data" id="shipping-info">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-delivery-address"
                          >
                            <input
                              type="radio"
                              className="form-check-input"
                              id="same-delivery-address"
                              name="shipping-address"
                              defaultValue=""
                              defaultChecked=""
                            />
                            Shipping Address is same as Delivery Address
                          </label>
                        </div>
                        <div className="form-check mb-4">
                          <label className="form-check-label" htmlFor="radio1">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="radio1"
                              name="shipping-address"
                              defaultValue=""
                              data-bs-toggle="collapse"
                              data-bs-target="#new-shipping-address"
                            />
                            Add New Shipping Address
                          </label>
                        </div>
                        <div className="row collapse" id="new-shipping-address">
                          <div className="col-sm-12 mb-3">
                            <label>Country / Region</label>
                            <select className="form-select" name="country">
                              <option value="india">India</option>
                            </select>
                          </div>
                          <div className="col-sm-6 mb-3">
                            <label>First Name</label>
                            <input
                              type="text"
                              name="first_name"
                              id="first_name"
                              placeholder="Enter your first name"
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-6 mb-3">
                            <label>Last Name</label>
                            <input
                              type="text"
                              name="last_name"
                              id="last_name"
                              placeholder="Enter your last name"
                              className="form-control"
                            />
                          </div>
                          <div className="col-lg-12 mb-3">
                            <label>Company</label>
                            <input
                              type="text"
                              name="company"
                              id="company"
                              placeholder="Company (Optional)"
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-12 mb-3">
                            <label>Address</label>
                            <input
                              type="text"
                              name="address"
                              id="address"
                              placeholder="Address"
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-12 mb-3">
                            <label>Appartment</label>
                            <input
                              type="text"
                              name="appartment"
                              id="appartment"
                              placeholder="appartment"
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-4 mb-3">
                            <label>City</label>
                            <input
                              type="text"
                              name="city"
                              id="city"
                              placeholder="city"
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-4 mb-3">
                            <label>State</label>
                            <input
                              type="text"
                              name="state"
                              id="state"
                              placeholder="state"
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-4 mb-3">
                            <label>Pincode</label>
                            <input
                              type="text"
                              name="pincode"
                              id="pincode"
                              placeholder="pincode"
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-12 mb-3">
                            <label>Phone Number</label>
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              placeholder="Phone No"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row m-0">
                          <div className="shipping-method">
                            <div className="">
                              Enter your shipping address to view available
                              shipping methods.
                            </div>
                          </div>
                          <button type="button" className="btn-continue btn">
                            Continue
                          </button>
                        </div>
                      </div>
                    )}
                  </div> */}
                <div className="payment">
                  <div className="head">
                    <div className="title"> Payment </div>
                    {/* <p className="content mb-0">
                  All Payments will be safe and secure.
                </p> */}
                  </div>
                  {paymentStage == 1 && (
                    <div className="checkout-data" id="payment">
                      <form onSubmit={paymentConfirm}>
                        <div className="form-check">
                          <label className="form-check-label" htmlFor="radio3">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="radio3"
                              name="payment"
                              defaultValue="1"
                              value="1"
                              checked
                              onChange={(e) => setPaymentType(e.target.value)}
                            />
                            Cash on Delivery
                          </label>
                        </div>

                        <div className="form-check">
                          <label className="form-check-label" htmlFor="radio1">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="radio1"
                              name="payment"
                              defaultValue=""
                              value="2"
                              onChange={(e) => setPaymentType(e.target.value)}
                            />
                            Credit / Debit Card
                          </label>
                        </div>
                        {/* <div className="card-payment">
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <label>Card Number</label>
                            <input
                              type="text"
                              name="address"
                              id="address"
                              placeholder="Enter Your Card No"
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-12 mb-3">
                            <label>Name on Card</label>
                            <input
                              type="text"
                              name="appartment"
                              id="appartment"
                              placeholder="Enter Your name on the card"
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-8 mb-3">
                            <label>Expiration Date</label>
                            <input
                              type="text"
                              name="city"
                              id="city"
                              placeholder="MM/YY"
                              className="form-control"
                            />
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label>CVV</label>
                            <input
                              type="text"
                              name="cvv"
                              id="cvv"
                              placeholder="CVV"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label" htmlFor="radio2">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="radio2"
                            name="payment"
                            defaultValue="option2"
                          />
                          DEBIT CARDS / NET BANKING / UPI / EMI / WALLETS
                        </label>
                      </div> */}

                        <button type="submit" className="btn btn-pay mt-4">
                          Confirm Order
                        </button>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {cart && (
              <div className="col-lg-5 border-start">
                <div className="order-summery">
                  <div className="items">
                    <h5 className="mb-sm-4 mb-3 ">Order Summery</h5>
                    {cart.map((list, key) => {
                      return (
                        <div className="order-item" key={key}>
                          <div className="row">
                            <div className="col-lg-2 col-sm-2 col-3 text-center">
                              <img
                                src={list.feature_image}
                                alt={list.product_name}
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-7 col-6">
                              <h6 className="art-name">{list.product_name}</h6>
                              <span>
                                {list.product_size}
                                {list.orientation_name
                                  ? " ," + list.orientation_name
                                  : ""}
                                {list.medium_name
                                  ? " ," + list.medium_name
                                  : ""}
                              </span>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-3 text-end">
                              <div className="art-price">
                                {list.product_price}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="item-total">
                    {/* <div className="discount-coupen mb-3">
                    <form action="" method="post">
                      <label htmlFor="discount_coupen">Discount Coupen</label>
                      <div className="row">
                        <div className="col-sm-9 col-8">
                          <input
                            type="text"
                            name="discount_coupen"
                            className="form-control py-2"
                            id="discount_coupen"
                          />
                        </div>
                        <div className="col-sm-3 col-4">
                          <button type="submit" className="btn btn-submit">
                            Apply
                          </button>
                        </div>
                      </div>
                    </form>
                  </div> */}
                    <div className="sub-total py-2">
                      <span className="th">Subtotal</span>
                      <span className="td">{subTotal}</span>
                    </div>
                    <div className="shipping-fee py-2">
                      <span className="th">Shipping Fee</span>
                      <span className="td">{shippingPrice}</span>
                    </div>
                    <div className="tax-fee py-2">
                      <span className="th">Tax Fee</span>
                      <span className="td">{taxPrice}</span>
                    </div>
                    <div className="total py-2">
                      <span className="th">Order Total</span>
                      <span className="td">{totalPrice}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
