import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { Layout } from "./Layout/Layout";
import store from "./Redux/store";
import { Provider } from "react-redux";
const queryClient = new QueryClient();

export default function App() {
  var sessionId = localStorage.getItem("session_id");
  if (!sessionId) {
    var min = 10000000000;
    var max = 99999999999;
    var rand = min + Math.random() * (max - min);
    var session_id = Date.now() + rand.toFixed(0);
    localStorage.setItem("session_id", session_id);
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {" "}
        <Layout />
      </Provider>
    </QueryClientProvider>
  );
}
